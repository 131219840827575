import { lm } from "../../tools/language/LanguageManager";

export class EmbeddedMessages {

    static NewFileName = () => lm({
    "de": "Neue Datei",
    "en": "new file",
    })

}
